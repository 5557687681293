
@import url("./fonts.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /*.markdown{
    width: 100%;
  }
  .markdown p{
    width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;
  }
  .markdown pre{
    width: 100%;
    box-sizing: border-box;
  }
  .markdown code{
    display: block;
    overflow: scroll;
    word-wrap: break-word;
    max-width: 100px;
  }*/
}

.my-element {
  @apply h-screen; /* Fallback using Tailwind's height utility */
  height: calc(var(--vh, 1vh) * 100); /* Using the custom property */
}

@layer components {

  .markdown code {
    @apply whitespace-pre-wrap block; /*w-1 es un workaround */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
